import { Route } from "react-router-dom";

//@ts-ignore
function PublicRoute({ component: Component, ...others }) {
  return (
    <Route
      {...others}
      render={(props) => {
        return <Component {...others} {...props} />;
      }}
    />
  );
}
export default PublicRoute;
