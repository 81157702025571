import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import CommonSlice from "./CommonSlice";
import GrowthHackingSlice from "./GrowthHackingSlice";
import UserSlice from "./UserSlice";
const rootReducer = {
  common: CommonSlice.reducer,
  growthHacking: GrowthHackingSlice.reducer,
  user: UserSlice.reducer,
};

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type StoreType = typeof store;
export default store;
