interface EnvInterface {
  greeting: string | undefined;
  customer_campaign_url: string | undefined;
  customer_insight_url: string | undefined;
}

const env: EnvInterface = {
  greeting: process.env.REACT_APP_GREETING,
  customer_campaign_url: process.env.REACT_APP_CUSTOMER_CAMPAIGN,
  customer_insight_url: process.env.REACT_APP_CUSTOMER_INSIGHT,
};

export default env;
