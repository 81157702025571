import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { PrivateRoute } from "../components";

const GrowthHacking = React.lazy(() => import("pages/GrowthNewCustomer"));
const HomePage = React.lazy(() => import("pages/HomePage"));

function MainRouter(props: any) {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
      <PrivateRoute
          exact
          path={"/"}
          component={HomePage}
          {...props}
        />
        <PrivateRoute
          exact
          path={"/growth-new-customer"}
          component={GrowthHacking}
          {...props}
        />
      </Switch>
    </Suspense>
  );
}

export default withRouter(MainRouter);
