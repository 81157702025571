import { RequestManager } from "utils";
import config from "configs/env";

const check_registered = (headers: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.customer_insight_url}/api/customer/check-registered`,
    {},
    headers
  );
};

const exported = {
  check_registered,
};

export default exported;
