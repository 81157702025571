import moment from "moment";

const getTimer = (currentTime: number) => {
  let duration = moment.duration(currentTime * 1000, "milliseconds");
  const day = duration.days();
  const hour = duration.hours();
  const minute = duration.minutes();
  const second = duration.seconds();

  return {
    days: day,
    hours: hour >= 10 ? hour : "0" + hour,
    minutes: minute >= 10 ? minute : "0" + minute,
    seconds: second >= 10 ? second : "0" + second,
  };
};

const exported = {
  getTimer,
};

export default exported;
