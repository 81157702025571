import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommonSlice from "./CommonSlice";
import { GrowthHacking as GROWTH_API } from "apis";
import moment from "moment";

const initialState = {
  list: [] as CustomerInviteType[],
  maximumTurn: 3 as number,
  isRefresh: false as boolean,
};

export const fetchListInviteCustomer = createAsyncThunk(
  "growthHacking/fetchListInviteCustomer",
  async (headers: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GROWTH_API.getInviteCustomer(headers);
      const { data } = response;
      return data as ListCustomerInviteType;
    } catch (error) {
      return {
        list: [],
      } as ListCustomerInviteType;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
      thunkApi.dispatch(GrowthHackingSlice.actions.resetIsRefresh());
    }
  }
);

export const inviteCustomer = createAsyncThunk(
  "growthHacking/inviteCustomer",
  async (params: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GROWTH_API.inviteCustomer(params);
      const { data } = response;
      return {
        data: data as InviteCustomerResponse,
        invitedPhone: params.invitedPhone as string,
        invitedName: params.invitedName as string,
      };
    } catch (error) {
      thunkApi.dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Lỗi! Đã xảy ra lỗi. Anh vui lòng thử lại sau nhé ạ!",
        })
      );
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const GrowthHackingSlice = createSlice({
  name: "growthHacking",
  initialState,
  reducers: {
    resetIsRefresh: (state) => {
      state.isRefresh = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListInviteCustomer.fulfilled, (state, action) => {
      state.list = action.payload.list;
    });
    builder.addCase(inviteCustomer.fulfilled, (state, action) => {
      if (action.payload?.data.status === 0) {
        let listDefault = [...state.list];
        const expDate = moment().add(30, "days");
        const newItem = {
          expDate: `23:59:59 ${expDate.format("DD/MM/YYYY")}`,
          invitedName: action.payload.invitedName,
          invitedPhone: action.payload.invitedPhone,
          status: 1,
        };
        listDefault.push(newItem);
        state.list = listDefault;
      }
    });
  },
});

export default GrowthHackingSlice;
