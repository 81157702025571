import "./styles/styles.scss";
import MainRouter from "./routers/MainRouter";
import { Spin, notification } from "antd";
import { useAppDispatch, useAppSelector } from "stores";
import { useEffect } from "react";
import CommonSlice from "stores/CommonSlice";
import UserSlice from "stores/UserSlice";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

function App(props: any) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { counterLoading, type, message } = useAppSelector(
    (state) => state.common
  );
  const data: any = queryString.parse(history.location.search);
  const { accessToken, token } = data;

  useEffect(() => {
    if (accessToken || token) {
      dispatch(UserSlice.actions.setAccessToken(accessToken || token));
    }
    // eslint-disable-next-line
  }, [accessToken, token]);

  useEffect(() => {
    if (message && type) {
      notification[type]({
        message: message,
        onClose: () => {
          dispatch(CommonSlice.actions.showNotice({ type: null, message: "" }));
        },
      });
    }
    // eslint-disable-next-line
  }, [type, message]);

  return (
    <Spin spinning={counterLoading > 0 ? true : false}>
      <div className="App">
        <MainRouter {...props} />
      </div>
    </Spin>
  );
}

export default App;
