import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CommonSlice from "./CommonSlice";
import { User as USER_API } from "apis";

const initialState = {
  accessToken: "" as string,
  user: {
    memberType: 0,
    membershipName: "",
    occupation: "",
    phoneNumber: "",
    totalMoneyBill: 0,
    userId: 0,
    userName: "",
  } as UserDataType,
};

export const checkRegistered = createAsyncThunk(
  "users/checkRegistered",
  async (headers: any, thunkApi) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await USER_API.check_registered(headers);
      const { data } = response;
      return data as CheckRegisteredType;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkRegistered.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.user = action.payload?.data;
      }
    });
  },
});

export default UserSlice;
