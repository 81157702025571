import { RequestManager } from "utils";
import config from "configs/env";

const getInviteCustomer = (headers: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.customer_campaign_url}/api/invite-customer?Type=1`,
    {},
    headers
  );
};

const inviteCustomer = (params: any) => {
  const { headers, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.post(
    `${config.customer_campaign_url}/api/invite-customer`,
    otherParams,
    headers
  );
};

const exported = {
  getInviteCustomer,
  inviteCustomer,
};

export default exported;
